<template>
  <v-dialog
    v-model="createModal"
    max-width="650px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Domain</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="buyDomain"
                  label="Buy a new domain"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="useOpenSRS"
                  label="Use OpenSRS as email provider"
                ></v-switch>
              </v-col>
              <v-col :cols="buyDomain ? '6' : '12'">
                <v-text-field
                  v-model="name"
                  label="Domain Name*"
                  required
                ></v-text-field>
                <span
                  v-if="showDomainAvailabilityResult && domainIsAvailable"
                  class="green--text"
                >
                  {{ name }} is available. :)
                </span>
                <span
                  v-else-if="showDomainAvailabilityResult && !domainIsAvailable"
                  class="red--text"
                >
                  {{ name }} is not available. :(
                </span>
              </v-col>
              <v-col cols="6" v-if="buyDomain">
                <v-btn
                  color="secondary"
                  class="my-5"
                  @click="checkDomainAvailability"
                  small
                  >Check availability</v-btn
                >
              </v-col>
              <v-col cols="12">
                <label>Accounts</label>
              </v-col>
              <v-col cols="12" v-if="accounts.length">
                <div v-for="account in accounts" :key="account.user">
                  {{ `${account.fullName} &lt;${account.user}@${name}&gt;` }}
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountFullName"
                  label="Full Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountUser"
                  label="Username*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="accountPassword"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  text
                  color="accent"
                  style="float: right"
                  @click="addAccount"
                  >Add account</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="createDomain()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      accounts: [],
      name: "",
      accountUser: "",
      accountPassword: "",
      accountFullName: "",
      buyDomain: false,
      showDomainAvailabilityResult: false,
      domainIsAvailable: false,
      useOpenSRS: true,
    };
  },
  props: {
    createModal: Boolean,
    selectedClient: undefined,
  },
  methods: {
    closeModal() {
      this.$emit("closeCreateModal");
    },
    async checkDomainAvailability() {
      try {
        const response = await this.$http.post("/domains/check", {
          domainName: this.name,
        });

        this.showDomainAvailabilityResult = true;

        if (response.data.available) {
          this.domainIsAvailable = true;
        } else {
          this.domainIsAvailable = false;
        }
      } catch (err) {
        this.showDomainAvailabilityResult = false;
      }
    },
    async createDomain() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post("/domains", {
          clientId: this.selectedClient,
          name: this.name,
          buyDomain: this.buyDomain,
          useOpenSRS: this.useOpenSRS,
          accounts: this.accounts,
        });
        this.$emit("domainSaved", response.data);
      } catch (err) {
        this.$emit("failedCreatingDomain", err);
      }
    },
    addAccount() {
      this.accounts.push({
        user: this.accountUser,
        password: this.accountPassword,
        fullName: this.accountFullName,
      });

      this.accountUser = "";
      this.accountPassword = "";
      this.accountFullName = "";
    },
  },
};
</script>
