var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"senders"},[_c('create-domain',{attrs:{"create-modal":_vm.createModal,"selected-client":_vm.selectedClient},on:{"closeCreateModal":_vm.closeCreateModal,"failedCreatingDomain":_vm.failedCreatingDomain,"domainSaved":_vm.domainSaved}}),_c('v-row',[_c('v-spacer'),(
        _vm.selectedDomain &&
        _vm.selectedDomain.length &&
        _vm.selectedDomain[0].domainStatus === 'UNVERIFIED'
      )?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.verifyDomain()}}},[_c('v-icon',[_vm._v("mdi-check-decagram-outline")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){_vm.createModal = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"click-table my-1",attrs:{"headers":_vm.headers,"items":_vm.domains,"item-key":"_id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"show-select":"","single-select":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
      var value = ref.value;
return [_c('time',{attrs:{"title":value,"datetime":value}},[_vm._v(_vm._s(_vm.formatDate(value)))])]}},{key:"item.accounts",fn:function(ref){
      var value = ref.value;
return _vm._l((value),function(account){return _c('v-chip',{key:account._id,staticClass:"mx-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(account.user)+" ")])})}},{key:"item.domainStatus",fn:function(ref){
      var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(value === 'UNVERIFIED')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"yellow darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-decagram-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Domain is currently unverified.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(value === 'VERIFIED')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-decagram-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Domain is verified.")])])]}}]),model:{value:(_vm.selectedDomain),callback:function ($$v) {_vm.selectedDomain=$$v},expression:"selectedDomain"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }