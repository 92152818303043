<template>
  <div class="senders">
    <create-domain
      :create-modal="createModal"
      :selected-client="selectedClient"
      @closeCreateModal="closeCreateModal"
      @failedCreatingDomain="failedCreatingDomain"
      @domainSaved="domainSaved"
    ></create-domain>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="
          selectedDomain &&
          selectedDomain.length &&
          selectedDomain[0].domainStatus === 'UNVERIFIED'
        "
        class="mx-2"
        fab
        dark
        small
        color="green"
        @click="verifyDomain()"
      >
        <v-icon>mdi-check-decagram-outline</v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo"
        @click="createModal = true"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-data-table
          v-model="selectedDomain"
          class="click-table my-1"
          :headers="headers"
          :items="domains"
          item-key="_id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="dataLoading"
          @click:row="handleRowClick"
          show-select
          single-select
        >
          <template v-slot:item.createdAt="{ value }">
            <time :title="value" :datetime="value">{{
              formatDate(value)
            }}</time>
          </template>
          <template v-slot:item.accounts="{ value }">
            <v-chip
              class="mx-1"
              v-for="account in value"
              :key="account._id"
              x-small
            >
              {{ account.user }}
            </v-chip>
          </template>
          <template v-slot:item.domainStatus="{ value }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="value === 'UNVERIFIED'"
                  color="yellow darken-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-alert-decagram-outline</v-icon
                >
              </template>
              <span>Domain is currently unverified.</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="value === 'VERIFIED'"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-check-decagram-outline</v-icon
                >
              </template>
              <span>Domain is verified.</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import CreateDomain from "@/components/CreateDomain.vue";
import { store } from "@/state/store.js";

export default {
  components: {
    CreateDomain,
  },
  data() {
    return {
      domains: [],
      sortBy: "createdAt",
      sortDesc: true,
      dataLoading: true,
      createModal: false,
      selectedDomain: undefined,
      store,
    };
  },
  created() {
    this.loadDomains();
  },
  computed: {
    headers() {
      return [
        {
          text: "Domain Name",
          value: "name",
        },
        {
          text: "Sender Accounts",
          value: "accounts",
        },
        {
          text: "Status",
          value: "domainStatus",
        },
        { text: "Date Created", value: "createdAt" },
      ];
    },
    selectedClient() {
      return this.store.selectedClient;
    },
  },
  watch: {
    selectedClient() {
      this.loadDomains();
    },
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format("MM/DD/YYYY hh:mm");
    },
    async loadDomains() {
      try {
        const domainsResp = await this.$http.get(
          `/domains?_filter=${this.selectedClient}`
        );

        this.domains = domainsResp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    closeCreateModal() {
      this.createModal = false;
    },
    failedCreatingDomain(error) {
      console.log(error);
      this.createModal = false;
    },
    domainSaved() {
      this.createModal = false;
      this.loadDomains();
    },
    async verifyDomain() {
      try {
        await this.$http.post("/domains/verify", {
          domainName: this.selectedDomain[0].name,
        });
        this.loadDomains();
      } catch (error) {
        console.log(error);
      }
    },
    handleRowClick(row) {
      this.$router.push({ name: "sender", params: { domainId: row._id } });
    },
  },
};
</script>
